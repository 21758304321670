import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentRadioButton.figmaUrl.ios} codeUrl={checklists.componentRadioButton.codeUrl.ios} checklists={checklists.componentRadioButton.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Create radio button with or without label. Radio Button is a component that allows the user to make a single choice between many options given.`}</p>
    <p>{`How to import your theme: Legion iOS UIKit offers four themes: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, and ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <p>{`The radio button can be used without any parameter`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNRadioButton();
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <h3>{`Size`}</h3>
    <p>{`You can customize the size of the button via the `}<inlineCode parentName="p">{`size`}</inlineCode>{` enum, where `}<inlineCode parentName="p">{`BaseLGNRadioButton.Size`}</inlineCode>{` includes `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{` and `}<inlineCode parentName="p">{`sm`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Large`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Medium`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Small`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/ios-uikit/ios-uikit-radio-large.png",
              "alt": "LGNRadioButton Large"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/ios-uikit/ios-uikit-radio-medium.png",
              "alt": "LGNRadioButton Medium"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/ios-uikit/ios-uikit-radio-small.png",
              "alt": "LGNRadioButton Small"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <p>{`Large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let radio = LGNRadioButton()
radio.size = .lg
radio.checked = true
radio.textLabel = "radio 3"
`}</code></pre>
    <p>{`Medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let radio2 = LGNRadioButton()
radio2.size = .md
radio2.checked = true
radio2.textLabel = "radio 2"
`}</code></pre>
    <p>{`Small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let radio3 = LGNRadioButton()
radio3.size = .sm
radio3.checked = true
radio3.textLabel = "radio 1"
`}</code></pre>
    <h3>{`Disable`}</h3>
    <p>{`You can also disable user interaction on `}<inlineCode parentName="p">{`LGNRadioButton`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Disabled`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/ios-uikit/ios-uikit-radio-default.png",
              "alt": "LGNRadioButton Disable Checked"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/radiobutton/ios-uikit/ios-uikit-radio-disabled.png",
              "alt": "LGNRadioButton Disabled"
            }}></img></td>
        </tr>
      </tbody>
    </table>
    <p>{`Checked Disabled`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let radio = LGNRadioButton()
radio.size = .md
radio.textLabel = "radio 2"
radio.disable = false
`}</code></pre>
    <p>{`Unchecked Disabled`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let radio2 = LGNRadioButton()
radio2.size = .md
radio2.textLabel = "radio 3"
radio2.disable = true
`}</code></pre>
    <div className="divi" />
    <h2>{`Usage Recommendation`}</h2>
    <p>{`In order to make LGNRadioButton works correctly it required to implements `}<inlineCode parentName="p">{`LGNRadioButtonDelegate`}</inlineCode>{` into your ViewController. Then your ViewController will add `}<inlineCode parentName="p">{`radioButtonDidTapped(selectedRadio: String)`}</inlineCode>{` function. And then, you can add radio button logic to that function. For example look at code below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`
class MainViewController: ViewController {
  override func viewDidLoad() {
    ...
    radio1.delegate = self
    radio2.delegate = self
  }

  func update(selectedRadioId: String){
      self.radio1.checked = (self.radio1.radioId == selectedRadioId)
      self.radio2.checked = (self.radio2.radioId == selectedRadioId)
  }
}

extension MainViewController: LGNRadioButtonDelegate{
  func radioButtonDidTapped(selectedRadioId: String) {
      self.update(selectedRadioId: selectedRadioId)
    }
}
`}</code></pre>
    <p>{`The code above will trigger `}<inlineCode parentName="p">{`update()`}</inlineCode>{` function as Radio Button logic everytime user select a Radio Button.`}</p>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determine if the radio disabled or not.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`radioId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Radio button identifier.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state of radio.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the radio.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.lg`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textLabel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the radio that describes it.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      